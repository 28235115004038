<template>
  <div v-if="vacancy" class="full-vacancy">
    <div class="container">
      <div class="full-vacancy__nav">
        <locale-router-link to="" class="crumbs-link main-page-link">
          {{ $t("home") }}</locale-router-link
        >
        <locale-router-link
          to="career"
          class="crumbs-link magnum-career-link"
          >{{ $t("сareerMagnum") }}</locale-router-link
        >
        <a class="crumbs-link magnum-career-full-link">{{ getConditions() }}</a>
      </div>

      <h1 class="full-vacancy__title">
        {{ getConditions() }}
      </h1>

      <div class="full-vacancy__experience">
        {{ $t("workExperience") }} {{ vacancy.attributes.required_experience }}
      </div>

      <!-- <div class="full-vacancy__tags">
        <div class="full-vacancy__tag">
          {{ vacancy.attributes.job_type.data.attributes.title }}
        </div>
      </div> -->

      <div class="full-vacancy__info">
        <div class="full-vacancy__descr">
          <!-- item 1 -->
          <div
            v-if="vacancy.attributes.todo"
            class="full-vacancy__item-wrapper"
          >
            <h3 class="full-vacancy__item-title">{{ $t("inOurTeam") }}</h3>
            <vue-markdown
              class="full-vacancy__list"
              :source="vacancy.attributes.todo"
            >
              {{ vacancy.attributes.todo }}
            </vue-markdown>
          </div>

          <!-- item 2 -->
          <div v-if="getKeySkills" class="full-vacancy__item-wrapper">
            <h3 class="full-vacancy__item-title">{{ $t("isImportant") }}</h3>

            <div class="full-vacancy__list">
              <ul>
                <li
                  v-for="skills in vacancy.attributes.key_skills"
                  :key="skills.id"
                >
                  {{ skills.title }}
                </li>
              </ul>
            </div>
          </div>

          <!-- item 3 -->
          <div
            v-if="vacancy.attributes.job_description"
            class="full-vacancy__item-wrapper"
          >
            <h3 class="full-vacancy__item-title">
              {{ $t("weExpected") }}
            </h3>
            <vue-markdown
              class="full-vacancy__list"
              :source="vacancy.attributes.job_description"
            >
            </vue-markdown>
          </div>

          <!-- item 4 -->
          <div
            v-if="vacancy.attributes.will_be_plus"
            class="full-vacancy__item-wrapper"
          >
            <h3 class="full-vacancy__item-title">
              {{ $t("isPlus") }}
            </h3>

            <vue-markdown
              class="full-vacancy__list"
              :source="vacancy.attributes.will_be_plus"
            >
            </vue-markdown>
          </div>

          <!-- item 5 -->
          <div
            v-if="vacancy.attributes.offer"
            class="full-vacancy__item-wrapper"
          >
            <h3 class="full-vacancy__item-title">
              {{ $t("ourAdvantage") }}
            </h3>

            <vue-markdown
              class="full-vacancy__list"
              :source="vacancy.attributes.offer"
            >
              {{ vacancy.attributes.offer }}
            </vue-markdown>
          </div>
        </div>
        <validation-observer ref="formObserver">
          <form
            ref="fullVacancyForm"
            class="full-vacancy__form"
            @submit.prevent="sendForm"
          >
            <h2 class="full-vacancy__form-title">
              {{ $t("applyForJob") }}
            </h2>
            <div class="full-vacancy__input-wrapper">
              <vacancy-form-select
                id="vacancies__select"
                v-model="userVacancy"
                v-if="vacanciesSelect"
                :items="vacanciesSelect"
                :firstSelect="vacancy"
                placeholder="vacancyPosition"
                field="conditions"
                getField="id"
              />
              <!-- <div v-else class="loader-mini__container">
                <div class="loader-mini"></div>
              </div> -->
              <validation-provider v-slot="{ errors }" rules="required">
                <span v-if="isShowValidate" class="full-vacancy__span">{{
                  errors[0] === "required" ? $t("enterName") : ""
                }}</span>
                <input
                  v-model="userName"
                  type="text"
                  :placeholder="$t('name')"
                  class="full-vacancy__input"
                />
              </validation-provider>
              <validation-provider v-slot="{ errors }" rules="required">
                <span v-if="isShowValidate" class="full-vacancy__span"
                  >{{ errors[0] === "required" ? $t("enterSurname") : "" }}
                </span>
                <input
                  v-model="userSurname"
                  type="text"
                  :placeholder="$t('surname')"
                  class="full-vacancy__input"
                />
              </validation-provider>

              <validation-provider v-slot="{ errors }" rules="email">
                <span class="full-vacancy__span">{{
                  errors[0] === "email" ? $t("emailIncorrectly") : ""
                }}</span>
                <input
                  v-model="userEmail"
                  placeholder="E-mail"
                  class="full-vacancy__input"
                />
              </validation-provider>
              <validation-provider v-slot="{ errors }" rules="required">
                <span v-if="isShowValidate" class="full-vacancy__span">{{
                  errors[0] === "required" ? $t("enterPhone") : ""
                }}</span>
                <input
                  v-model="userPhone"
                  v-mask="'+#(###)###-##-##'"
                  type="text"
                  :placeholder="$t('phoneNumber')"
                  class="full-vacancy__input"
                />
              </validation-provider>
              <validation-provider v-slot="{ errors }" rules="required">
                <span v-if="isShowValidate" class="full-vacancy__span">{{
                  errors[0] === "required" ? $t("enterCity") : ""
                }}</span>
                <input
                  v-model="userCity"
                  type="text"
                  :placeholder="$t('formCity')"
                  class="full-vacancy__input full-vacancy__input--last-child"
                />
              </validation-provider>
              <!-- <vacancy-form-select
                v-model="user.city"
                v-if="cities"
                :items="cities"
                :firstSelect="selectedCity"
                placeholder="enterCity"
                field="name"
                getField="name"
              /> -->
            </div>
            <button class="full-vacancy__button" type="submit">
              {{ $t("send") }}
            </button>
          </form>
        </validation-observer>
        <!-- Info -->
      </div>

      <div class="full-vacancy__tags">
        <template v-if="isCities">
          <div
            v-for="(item, index) in vacancy.attributes.cities.data"
            :key="index"
            class="full-vacancy__tag"
          >
            {{ item.attributes.name }}
          </div>
        </template>
      </div>

      <div class="full-vacancy__contacts">
        <h3>{{ $t("vacancyContacts.title") }}</h3>
        <div class="full-vacancy__contacts-wrapper">
          <a href="tel:998555000066" class="full-vacancy__contacts-number"
            >{{ $t("vacancyContacts.number") }}
          </a>
          <a
            href="https://t.me/mgmuz_bot"
            target="_blank"
            class="full-vacancy__contacts-email"
            >@magnum_jbot</a
          >
        </div>
        <div class="hashtags">
          <div class="hashtag">#могувмагнум</div>
          <div class="hashtag">#Магнумдаиложибор</div>
        </div>
      </div>

      <div v-if="vacancies.length" class="full-vacancy__other">
        <h3 class="full-vacancy__other-title">{{ $t("otherVacancies") }}</h3>
        <swiper ref="swiperVacancyRef" :options="swiperOption">
          <swiper-slide v-for="(item, index) in vacancies" :key="index">
            <locale-router-link
              :to="`vacancy/${item.id}`"
              class="full-vacancy__other-item"
            >
              <h6 v-if="item.attributes.conditions">
                {{ item.attributes.conditions }}
              </h6>
              <p>
                {{ $t("workExperience") }}
                {{
                  item.attributes.required_experience
                    ? item.attributes.required_experience
                    : "без опыта"
                }}
              </p>
            </locale-router-link>
          </swiper-slide>
        </swiper>
        <div class="slider-pagination-wrapper">
          <div
            class="pagination__custom-production swiper-pagination"
            slot="pagination"
          ></div>
        </div>
        <button @click="prevSlide" class="swiper-button-prev">
          <img src="@/assets/img/icons/arrow-home.svg" alt="" />
        </button>
        <button @click="nextSlide" class="swiper-button-next">
          <img src="@/assets/img/icons/arrow-home.svg" alt="" />
        </button>
      </div>

      <!-- modal -->
      <div v-if="isSuccessReply" class="popup-overlay" @click="closeModal">
        <div class="popup" @click.stop>
          <div class="full-vacancy__modal">
            <button
              class="full-vacancy__modal-close"
              @click="closeModal"
            ></button>
            <div class="full-vacancy__modal-title">
              {{ $t("receivedYourApplication") }}
            </div>
            <div class="full-vacancy__modal-subtitle">
              {{ $t("ThankFeedback") }}
            </div>
            <div class="full-vacancy__modal-img">
              <img
                src="@/assets/img/magnum-career/modal.svg"
                alt="modal image"
              />
            </div>
            <button class="full-vacancy__modal-btn" @click="closeModal">
              {{ $t("fine") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVacancy, sendReply, getFilteredVacancies } from "@/api/vacancy";
import VueMarkdown from "vue-markdown";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapState } from "vuex";
export default {
  name: "FullVacancy",
  components: {
    VacancyFormSelect: () =>
      import("@/components/vacancy/VacancyFormSelect.vue"),
    VueMarkdown,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      vacancy: null,
      vacancies: [],
      vacanciesSelect: null,

      userName: "",
      userSurname: "",
      userPhone: "",
      userEmail: "",
      userCity: "",
      userVacancy: "",

      isSuccessReply: false,
      isShowValidate: false,
      pagination: {
        page: 1,
        pageSize: 20,
        isLastPage: false,
      },
      swiperOption: {
        slideTo: 1,
        spaceBetween: 20,
        loop: true,
        loopedSlides: null,
        loopAdditionalSlides: 0,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".pagination__custom-production",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
          },
          600: {
            slidesPerView: 3,
          },
          450: {
            slidesPerView: 2,
          },
          300: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
  computed: {
    ...mapState("vacancyModule", ["filteredVacancy"]),
    ...mapState("cityModule", { cities: "data", selectedCity: "selectedCity" }),
    swiper() {
      return this.$refs?.swiperVacancyRef;
    },
    isCities() {
      return !!this.vacancy.attributes?.cities?.data?.length;
    },
  },
  watch: {
    "$route.params.id": {
      deep: true,
      handler() {
        getVacancy(Number(this.$attrs.id)).then((vacancy) => {
          this.vacancy = vacancy;
          this.initLocaleVacancy();
        });

        getFilteredVacancies(
          { jobType: null, position: null },
          this.pagination,
          Number(this.$attrs.id)
        ).then((vacancies) => {
          this.vacancies = vacancies.data;
        });
      },
    },
    userVacancy: {
      handler() {
        if (this.userVacancy !== this.vacancy.id) {
          this.$router.push({
            params: { id: this.userVacancy },
            query: { city_id: this.selectedCity.id || 1 },
          });
        }

        // location.reload();
      },
    },
    isSuccessReply: {
      handler() {
        console.log(this.isSuccessReply);
      },
    },
  },
  created() {
    getVacancy(Number(this.$attrs.id)).then((vacancy) => {
      this.vacancy = vacancy;
      console.log("vacancy", this.vacancy);
      this.userVacancy = vacancy.id;
      this.initLocaleVacancy();
    });
    getFilteredVacancies(this.filteredVacancy, this.pagination).then(
      (vacancies) => {
        this.vacanciesSelect = vacancies.data;
      }
    );
    getFilteredVacancies(
      { jobType: null, position: null },
      this.pagination,
      Number(this.$attrs.id),
      this.filteredVacancy
    ).then((vacancies) => {
      this.vacancies = vacancies.data;
      console.log("vacancies", this.vacancies);
    });
  },
  methods: {
    initLocaleVacancy() {
      if (this.$i18n.locale === "kz") {
        this.vacancy.attributes.cities.data =
          this.vacancy?.attributes.cities.data?.map((c) => {
            c.attributes = c.attributes.localizations.data[0].attributes;
            return c;
          });
        this.vacancy.attributes.job_type.data.attributes =
          this.vacancy.attributes.job_type.data.attributes.localizations?.data[0]?.attributes;
        getVacancy(this.vacancy.related_id).then((vacancyKz) => {
          this.vacancy.attributes.key_skills = vacancyKz.attributes.key_skills;
        });
      }
    },
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper?.swiperInstance.slideNext();
    },
    sendForm() {
      this.isShowValidate = true;
      this.$refs.formObserver.validate().then(async (success) => {
        if (!success) {
          return;
        }
        const user = {
          name: this.userName,
          surname: this.userSurname,
          phone: this.userPhone,
          email: this.userEmail,
          city: this.userCity,
          vacancy: this.userVacancy,
        };

        console.log(user);
        const that = this;

        try {
          await sendReply({ data: user });

          this.isSuccessReply = true;

          that.$nextTick(() => {
            that.$refs.fullVacancyForm.reset();
          });
          this.userName = "";
          this.userSurname = "";
          this.userPhone = "";
          this.userEmail = "";
          this.userCity = "";
          this.isShowValidate = false;
        } catch (e) {
          console.log(e.response);
        }
      });
    },
    getKeySkills() {
      return this.vacancy?.attributes?.key_skills?.length;
    },
    getConditions() {
      return this.vacancy?.attributes?.conditions;
    },

    closeModal() {
      this.isSuccessReply = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#vacancies__select {
  margin-bottom: 15px;
}

.full-vacancy li {
  list-style-type: initial !important;
}
.swiper-button-next {
  left: auto;
  right: -70px !important;
  transform: translateY(50%) rotate(180deg);
}
.swiper-button-prev {
  left: -70px !important;
  transform: translateY(50%) rotate(0);
}

.slider-pagination-wrapper {
  display: none;
  bottom: -20px;
}
.swiper-wrapper {
  align-items: center;
}

@media (max-width: 1300px) {
  .swiper-button-next {
    right: -33px !important;
  }
  .swiper-button-prev {
    left: -33px !important;
  }
}

@media (max-width: 600px) {
  .slider-pagination-wrapper {
    display: flex;
  }
}

.popup-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.4);
  z-index: 999;
}
.popup {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &__header {
    width: 100%;
    justify-content: flex-end;
  }
  &__button-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 44px;
    height: 44px;
    // background: transparent url('~@/assets/images/icons/close.svg') no-repeat;
    background-position: center center;
    border: none;
  }
  .hide {
    display: none;
  }
}
</style>
